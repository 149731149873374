<template>
  <div>
    <liff-list :listKey="listKey"></liff-list>
  </div>
</template>

<script lang="babel" type="text/babel">
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./clientStoreOrderList"

export default {
  mixins: [listMixin],
  props: {
    storeId: String
  },
  components: {

  },
  data: () => ({
    listKey: 'liff-provider-store-order-list',
    meta: {},
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`]
    },
    status() {
      if(!this.listFilter) return null
      return this.listFilter.status
    },
    title() {
      if(!this.status) return ''
      return this.$t(`provider.status.${this.status}`)
    },
  },
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      params.filter = {
        ...params.filter,
        store: this.storeId
      }
      return await this.$api.collection.providerApi.getOrders(this.providerId, params)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
