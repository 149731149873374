import Vue from 'vue'
import { LiffListConfigInterface, TableDataType } from '@/components/list/types/list'

class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      searchFilterOrder: 0,
      pageTitle: 'module.order',
      search: '輸入訂單編號搜尋',
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      liffFilter: {
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      listItem: () => import('@/modules/provider/views/order/orderCardItem.vue'),
    }

  }
}

export default new listConfig()
